<template>
  <div class="content d-flex flex-column flex-column-fluid" id="kt_content">
    <!--begin::Post-->
    <div class="post d-flex flex-column-fluid" id="kt_post">
      <!--begin::Container-->

      <div id="kt_content_container" class="container-xxl">
        <!--begin::Card-->
        <div class="card">
          <!--begin::Card body-->
          <div class="card-body">
            <div class="row">
              <div class="col-12">
                <label class="form-label" for=""
                  >Pengajuan personel SPERS TNI yang belum ada di daftar
                  verifikasi :</label
                >
                <div class="input-group mb-3">
                  <input
                    type="text"
                    data-kt-customer-table-filter="search"
                    class="form-control form-control-solid ps-13"
                    placeholder="NRP personel"
                    autofocus=""
                    v-model="nipNrp"
                  />
                  <button class="btn btn-success" @click="submitPersonRequest">
                    Tambahkan
                  </button>
                </div>
                <hr />

                <!--begin::Input-->
                <div
                  class="input-group mb-3"
                  v-for="(v, k) in personRequests"
                  :key="k"
                >
                  <span class="input-group-text" id="basic-addon2"
                    ><i class="fa fa-spinner"></i
                  ></span>
                  <input
                    type="text"
                    class="form-control"
                    :value="v.nip_nrp"
                    disabled=""
                  />
                  <button
                    type="button"
                    class="btn btn-danger"
                    @click="deleteConfirm(v.id)"
                  >
                    Hapus
                  </button>
                </div>
                <!--end::Input-->
              </div>
            </div>
          </div>
          <!--end::Card body-->
        </div>
        <!--end::Card-->
      </div>
      <!--end::Container-->
    </div>
    <!--end::Post-->
  </div>
</template>

<script>
import { toRaw } from "vue";
import Service from "@/services/base.service";

export default {
  mounted() {
    this.init();
  },
  data() {
    return {
      nipNrp: "",
      personRequests: [],
    };
  },
  methods: {
    init() {
      this.fetchPersonRequest();
    },
    async fetchPersonRequest() {
      try {
        const { user } = toRaw(this.$store.state.auth);
        const service = new Service("person-request");
        const { data } = await service.getListData({ user_id: user.id });
        this.personRequests = data;
      } catch (error) {
        console.log(error);
      }
    },
    async submitPersonRequest() {
      try {
        const service = new Service("person-request");
        await service.createData({
          nip_nrp: this.nipNrp,
        });
        this.nipNrp = "";
        this.fetchPersonRequest();
      } catch (error) {
        console.log(error);
      }
    },
    async deletePersonRequest(id) {
      try {
        const service = new Service("person-request");
        await service.deleteData(id);
        this.nipNrp = "";
        this.fetchPersonRequest();
      } catch (error) {
        console.log(error);
      }
    },
    deleteConfirm(id) {
      this.$swal
        .fire({
          title: "Are you sure?",
          text: "You won't be able to revert this!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes, delete it!",
        })
        .then((result) => {
          if (result.isConfirmed) {
            this.deletePersonRequest(id);
          }
        });
    },
  },
};
</script>
