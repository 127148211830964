<template>
  <div class="post" id="kt_post">
    <!--begin::Container-->
    <div id="kt_content_container" class="container-xxl">
      <!--begin::Card-->
      <div class="card">
        <!--begin::Card body-->
        <div class="card-body">
          <!--begin::Table-->
          <base-table
            ref="table"
            :columns="columns"
            :service="`person?period=${period}`"
            :defaultFilter="searchParams"
          >
            <template #header>
              <div class="row mb-5">
                <div class="col-auto">
                  <h4 class="mt-3">Periode Data</h4>
                </div>
                <div class="col">
                  <select
                    v-model="period"
                    class="form form-control"
                    placeholder="Search..."
                    @change="handleChangePeriod"
                  >
                    <option
                      v-for="(option, key) in periodOptions"
                      :key="key"
                      :value="option.value"
                    >
                      {{ option.label }}
                    </option>
                  </select>
                </div>
              </div>
              <div class="row mt-5 mb-5">
                <div class="col">
                  <v-select
                    :filterable="false"
                    :options="satkerOptions"
                    @search="onSearch"
                    v-model="searchParams.satker"
                    @option:selected="hanldeSatkerSelected"
                  >
                  </v-select>

                  <Treeselect
                    v-if="false"
                    v-model="value"
                    :multiple="true"
                    :options="options"
                  />
                </div>
              </div>
              <div class="row">
                <!-- <div class="col-auto">
                  <div class="btn-group" role="group">
                    <button
                      id="btnGroupDrop1"
                      type="button"
                      class="btn btn-dark font-weight-bold dropdown-toggle"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      Action
                    </button>
                    <div class="dropdown-menu" aria-labelledby="btnGroupDrop1">
                      <button @click="batchAction" class="dropdown-item">
                        Ajukan KTA
                      </button>
                    </div>
                  </div>
                </div> -->
                <div class="col">
                  <select
                    v-model="searchParams.dimension"
                    class="form form-control"
                    placeholder="Search..."
                  >
                    <option value="">Pilih Matra</option>
                    <option
                      v-for="(item, index) in matraOptions"
                      :key="index"
                      :value="item.value"
                    >
                      {{ item.label }}
                    </option>
                  </select>
                </div>
                <div class="col">
                  <select
                    v-model="searchParams.rank"
                    class="form form-control"
                    placeholder="Search..."
                  >
                    <option value="">Pilih Pangkat</option>
                    <option
                      v-for="(item, index) in pangkatOptions"
                      :key="index"
                      :value="item.value"
                    >
                      {{ item.label }}
                    </option>
                  </select>
                </div>
                <div class="col">
                  <select
                    v-model="searchParams.position"
                    class="form form-control"
                    placeholder="Search..."
                  >
                    <option value="">Pilih Jabatan</option>
                    <option
                      v-for="(item, index) in jabatanOptions"
                      :key="index"
                      :value="item.value"
                    >
                      {{ item.label }}
                    </option>
                  </select>
                </div>
                <div class="col">
                  <select
                    v-model="searchParams.status"
                    class="form form-control"
                    placeholder="Search..."
                  >
                    <option
                      v-for="(option, key) in statusOptions"
                      :key="key"
                      :value="option.value"
                    >
                      {{ option.label }}
                    </option>
                  </select>
                </div>
                <div class="col">
                  <input
                    type="text"
                    name="search"
                    placeholder="Search..."
                    class="form form-control"
                    v-model="searchParams.search"
                  />
                </div>
                <div class="col-auto">
                  <button class="btn btn-primary" @click="reloadTable">
                    Filter
                  </button>
                  <!-- <button
                    class="btn btn-secondary"
                    @click="$router.push({ name: 'submission-kta-upload' })"
                  >
                    Import
                  </button> -->
                </div>
              </div>
            </template>

            <template #action-button="{ data }">
              <div v-if="!data.value.verified_at">
                <button
                  class="btn btn-success btn-sm"
                  @click="
                    $router.push({
                      name: 'verifikasi',
                      params: { personId: data.value.id },
                    })
                  "
                >
                  Verifikasi
                </button>
              </div>
              <div v-else>
                <button
                  v-if="isVerifiable"
                  class="btn btn-success btn-sm"
                  @click="
                    $router.push({
                      name: 'verifikasi',
                      params: { personId: data.value.id },
                    })
                  "
                >
                  Ubah
                </button>
                <button
                  class="btn btn-primary btn-sm"
                  @click="shareChangeData(data.value, true)"
                >
                  Lihat
                </button>
                <button
                  class="btn btn-secondary btn-sm"
                  @click="shareChangeData(data.value)"
                >
                  Bagikan
                </button>
                <!-- <button
                  class="btn btn-primary btn-sm"
                  @click="handleActionButton(data.value)"
                >
                  Ajukan KTA
                </button> -->
              </div>
            </template>
          </base-table>

          <!--end::Table-->
        </div>
        <!--end::Card body-->
      </div>
      <!--end::Card-->
    </div>
    <!--end::Container-->
    <PersonRequest></PersonRequest>
  </div>
</template>

<script>
import Treeselect from "vue3-treeselect";
import "vue3-treeselect/dist/vue3-treeselect.css";
import "vue-select/dist/vue-select.css";

import { toRaw } from "vue";
import BaseTable from "@/components/BaseTable.vue";
import Service from "@/services/base.service";
import PersonRequest from "@/components/PersonRequest.vue";

export default {
  components: {
    BaseTable,
    PersonRequest,
    Treeselect,
  },
  data() {
    return {
      searchParams: {
        search: "",
        status: "",
        rank: "",
        dimension: "",
        position: "",
        satker: null,
        satkerid: null,
        parent_kode: null,
      },
      period: 0,
      statusOptions: [
        { label: "Pilih Status", value: "" },
        { label: "Belum Verifikasi", value: "UPLOADED" },
        { label: "Terverifikasi", value: "VERIFIED" },
      ],
      file: "",
      columns: [
        // { field: "id", title: "ID", isUnique: true, type: "number" },
        // { field: "card_type.name", title: "Jenis KTA" },
        { field: "nama_lengkap", title: "Nama" },
        { field: "dimension.name", title: "Matra" },
        { field: "rank.name", title: "Pangkat/Korps" },
        { field: "nip_nrp", title: "NRP/NBI/NIP" },
        { field: "position.name", title: "Jabatan" },
        { field: "unity.name", title: "Kesatuan" },
        { field: "satker.namasingkat", title: "Satker" },
        // { field: "createdAt", title: "CreatedAt" },
        { field: "verified_at", title: "Tgl Verifikasi" },
        { field: "status", title: "Status" },
        { field: "actions", title: "Actions" },
      ],
      matraOptions: [],
      pangkatOptions: [],
      jabatanOptions: [],
      kesatuanOptions: [],
      periodOptions: [],
      config: {
        roots: ["id1", "id2"],
      },
      nodes: {
        id1: {
          text: "text1",
          children: ["id11", "id12"],
        },
        id11: {
          text: "text11",
        },
        id12: {
          text: "text12",
        },
        id2: {
          text: "text2",
        },
      },
      value: null,
      options: [
        {
          id: "a",
          label: "a",
          children: [
            {
              id: "aa",
              label: "aa",
            },
            {
              id: "ab",
              label: "ab",
            },
          ],
        },
        {
          id: "b",
          label: "b",
        },
        {
          id: "c",
          label: "c",
        },
      ],
      satkerSelected: [],
      satkerOptions: [],
    };
  },
  watch: {
    // searchParams: {
    //   handler(val) {
    //     // do stuff
    //     console.log(val);
    //   },
    //   deep: true,
    // },
  },
  mounted() {
    this.init();
  },
  computed: {
    isVerifiable() {
      const selectedPeriodRaw = this.periodOptions.filter(
        (r) => r.value == this.$route.query.period
      );
      const { start_at, end_at } = toRaw(selectedPeriodRaw[0]);

      const check = this.dateCheck(start_at, end_at, new Date());

      return check;
    },
  },
  methods: {
    addTag(newTag) {
      const tag = {
        name: newTag,
        code: newTag.substring(0, 2) + Math.floor(Math.random() * 10000000),
      };
      this.satkerOptions.push(tag);
      this.satkerSelected.push(tag);
    },
    dateCheck(from, to, check) {
      const fDate = Date.parse(from);
      const lDate = Date.parse(to);
      const cDate = Date.parse(check);
      return cDate >= fDate && cDate <= lDate;
    },
    async init() {
      const matraService = new Service("master/matra");
      const pangkatService = new Service("master/pangkat");
      const jabatan = new Service("master/jabatan");
      const kesatuan = new Service("master/kesatuan");

      const [matraData, pangkatData, jabatanData, kesatuanData] =
        await Promise.all([
          matraService.getListData(),
          pangkatService.getListData(),
          jabatan.getListData(),
          kesatuan.getListData(),
        ]);

      this.matraOptions = matraData.data.map((r) => {
        return { value: r.id, label: r.name };
      });
      this.pangkatOptions = pangkatData.data.map((r) => {
        return { value: r.id, label: r.name };
      });
      this.jabatanOptions = jabatanData.data.map((r) => {
        return { value: r.id, label: r.name };
      });
      this.kesatuanOptions = kesatuanData.data.map((r) => {
        return { value: r.id, label: r.name };
      });

      this.fetchPeriode();
    },
    async fetchPeriode() {
      try {
        const service = new Service("period");
        const { data } = await service.getListData({ pagesize: 999999 });
        this.periodOptions = data.map((r) => {
          return {
            value: r.id,
            label: `${r.start_at} - ${r.end_at}`,
            start_at: r.start_at,
            end_at: r.end_at,
          };
        });
        if (this.$route.query.period) {
          this.period = this.$route.query.period;
          setTimeout(() => {
            this.$refs.table.reload();
          }, 500);
          ``;
        }
      } catch (error) {
        console.log(error);
      }
    },
    handleChangePeriod() {
      this.$router.push({
        name: "verification-verify",
        query: { period: this.period },
      });
      setTimeout(() => {
        this.$refs.table.reload();
      }, 500);
    },
    handleUploadFile() {
      this.file = this.$refs.file.files[0];
    },
    async submitFileUpload() {
      let formData = new FormData();
      formData.append("file", this.file);
      const service = new Service("attachment/upload");
      try {
        const { data } = await service.uploadFile(formData);
        const { id } = data;
        const serviceProcess = new Service(`attachment/process/${id}`);
        const resultProcess = await serviceProcess.createData({
          task: "import-personil",
          do: ["submit-cardApplication"],
        });
        console.log("resultProcess", resultProcess);
        this.$refs.file.value = null;
      } catch (error) {
        console.log(error);
      }
    },
    async confirmAJukan(selectedData) {
      await this.$swal
        .fire({
          title: "Some multiple input",
          html:
            '<input ref="kta_number" name="kta_number" id="swal-input1" class="form form-control" placeholder="Nomor KTA">' +
            '<input ref="card_type_name" name="card_type_name" id="swal-input2" class="form form-control" placeholder="Jenis Kartu">',
          showCancelButton: true,
          confirmButtonText: "Ajukan",
          showLoaderOnConfirm: true,
          allowOutsideClick: () => !this.$swal.isLoading(),
          preConfirm: async () => {
            const kta_number = document.querySelector(
              "input[name=kta_number]"
            ).value;
            const card_type_name = document.querySelector(
              "input[name=card_type_name]"
            ).value;
            if (!kta_number || !card_type_name) {
              this.$swal.showValidationMessage(
                "Nomor KTA dan Jenis KTA Wajib diisi!"
              );
            } else {
              try {
                const cardApplicationService = new Service("card-application");
                const cardApplicationParam = {
                  kta_number: kta_number,
                  person_id: selectedData.id,
                  card_type_name: card_type_name,
                };
                return await cardApplicationService.createData(
                  cardApplicationParam
                );
              } catch (error) {
                console.log(error);
              }
            }
          },
        })
        .then((result) => {
          if (result.isConfirmed) {
            const { data, message } = result.value;
            console.log(data, message);
            this.$swal.fire({
              title: message,
              message: `Berhasil ${data.status} ID ${data.id}`,
            });
          }
        });
    },
    handleActionButton(value) {
      const selectedData = toRaw(value);
      this.confirmAJukan(selectedData);
    },
    batchAction() {
      const data = this.$refs.table.getSelectedRows();
      const ids = data.map((r) => {
        return r.id;
      });
      if (ids.length < 1) {
        this.$swal.fire({
          title: "Warning!",
          text: "Tidak ada data dipilih",
          icon: "info",
        });
        return;
      }
      this.$swal
        .fire({
          title: `${ids.length} Data Dipilih`,
          text: "Yakin akan mengajukan KTA",
          icon: "warning",
        })
        .then((result) => {
          if (result.isConfirmed) {
            this.doRequestApproval(ids);
          }
        });
    },
    doRequestApproval(ids) {
      ids.forEach((e) => {
        console.log(e);
      });
    },
    async shareChangeData(selectedData, openNewTab = false) {
      const rawData = toRaw(selectedData);
      const service = new Service(`person/${rawData.id}/share`);
      const { data } = await service.getListData(rawData.id);
      const { url } = data;
      if (openNewTab) {
        window.open(url);
      } else {
        this.$swal
          .fire({
            title: "Bagikan riwayat perubahan data terakhir",
            html: `<input value="${url}" ref="card_type_name" name="card_type_name" id="swal-input2" class="form form-control">`,
            showCancelButton: true,
            confirmButtonText: "Copy Link",
            showLoaderOnConfirm: true,
            allowOutsideClick: () => !this.$swal.isLoading(),
            // preConfirm: async () => {
            //   navigator.clipboard.writeText(url);
            // },
          })
          .then((result) => {
            if (result.isConfirmed) {
              navigator.clipboard.writeText(url);
              this.$swal.fire({
                title: `Link berhasil di copy: ${url}`,
              });
            }
          });
      }
    },
    onSearch(search, loading) {
      if (search.length) {
        loading(true);
        const service = new Service("satker");
        service.getListData({ search }).then(({ data }) => {
          this.satkerOptions = data.map((m) => {
            return {
              label: `${m.satkerid} - ${m.namasingkat}`,
              value: m.id,
              satkerid: m.satkerid,
            };
          });
          loading(false);
        });
      }
    },
    hanldeSatkerSelected(value) {
      const raw = toRaw(value);
      this.searchParams.satkerid = raw.satkerid;
    },
    reloadTable() {
      if (this.searchParams.satker) {
        this.searchParams.satkerid = this.searchParams.satker.satkerid;
      } else {
        this.searchParams.satkerid = null;
      }
      this.$refs.table.reload();
    },
  },
};
</script>
